import Settings from '@/settings/names'
import _ from 'lodash'

export default {

    data(){
        return {
            names: Settings.NAMES,
            genders: Settings.GENDERS,
        }
    },

    methods: {
        getRandomRegion(){
            return _.shuffle(Settings.NAMES.map (name=>name.region))[0]
        }
    },

    computed: {
        regions (){
            return this.names.map (name=>name.region)
        },

        firstNames (){
            const { gender } = this
            if (!gender) return []

            let [regionWiseFilter] = this.names.filter (name=>{
                return name.region == this.region
            })
            if (!regionWiseFilter || regionWiseFilter.length<1) return

            if (['both', 'random'].includes(gender)) {
                return [...regionWiseFilter.male, ...regionWiseFilter.female]
            }
            return regionWiseFilter[gender]
        },

        surnames (){
            const { gender } = this
            if (!gender) return []

            let [regionWiseFilter] = this.names.filter(name=>{
                return name.region == this.region
            })

            return regionWiseFilter?.surnames || []
        }
    },
}