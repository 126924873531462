<template>
  <el-container>
    <el-header height="auto">
      <div id="nav">
        <nav-menu />
      </div>
    </el-header>
    <el-main>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view> 
    </el-main>
  </el-container>
</template>

<script>
import NavMenu from '@/components/theme/NavMenu'
export default {
  components: {
    NavMenu
  }
}
</script>