<template>
  <div class="home">
    <div class="intro">
      <h1 style="font-size: 6vw">
        Hello { Developers }
        <!-- {{ $t('homepage.head.intro-title', ['Developers']) }} -->
      </h1>
      <p>
        Generate placeholder text, images and common random names by region.
      </p>
    </div>
    <el-divider />
    <!-- Demo -->
    <div class="home-page-cards">
      <home-page-cards />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomePageCards from "@/components/home/HomePageCards"

export default {
  name: 'Home',
  components: {
    HomePageCards
  }
}
</script>
