<template>
    <el-row>
        <el-col 
            type="mobile-menu vertical" 
            class="hidden-md-and-up"
        >
            <div class="text-right">
                <el-button @click="showMobileMenu = !showMobileMenu">
                    {{ toggleBtnText }}
                </el-button>
            </div>
            <transition name="slide">
                <el-menu 
                    v-if="showMobileMenu"
                    class="el-menu-demo" 
                    mode="vertical"
                    :router="true"
                >
                    <el-menu-item 
                        @click="showMobileMenu = false"
                        v-for="(item, key) in items"
                        :key="key"
                        :index="item.key" 
                        :route="item.route"
                    >
                        {{ item.title }}
                    </el-menu-item>
                </el-menu>
            </transition>
        </el-col>
        <el-col 
            type="large-screen-menu horizontal"
            class="hidden-sm-and-down" 
        >
            <el-menu 
                class="el-menu-demo" 
                mode="horizontal"
                :router="true"
            >
                <el-menu-item 
                    v-for="(item, key) in items"
                    :key="key"
                    :index="item.key" 
                    :route="item.route"
                >
                    {{ item.title }}
                </el-menu-item>
            </el-menu>
        </el-col>
    </el-row>
</template>

<script>

export default {

    data(){
        return {
            items: [
                { key: "1", route: "/", title: this.$t('nav-menu.home') },
                { key: "2", route: "/lorem-ipsum", title: this.$t('nav-menu.lipsum') },
                { key: "3", route: "/names", title: this.$t('nav-menu.names') },
                { key: "4", route: "/image", title: this.$t('nav-menu.placeholder-images') },
            ],
            showMobileMenu: false
        }
    },

    methods: {

    },

    computed: {
        toggleBtnText (){
            if (this.showMobileMenu) 
                return 'X'
            return this.$t('nav-menu.menu-toggle-text') 
        }
    }
}
</script>
