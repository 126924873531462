import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const DEFAULT_TITLE = 'Placeholder' 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/lorem-ipsum',
    name: 'Lorem Ipsum',
    // this generates a separate chunk (lipsum.[hash].js) : lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lipsum" */ '../views/LoremIpsum.vue')
  },
  {
    path: '/names',
    name: 'Names',
    // this generates a separate chunk (names.[hash].js) : lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "names" */ '../views/Names.vue')
  },
  {
    path: '/image',
    name: 'Image',
    // this generates a separate chunk (image.[hash].js) : lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "image" */ '../views/PlaceholderImage.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.title || toRoute.name || DEFAULT_TITLE;
  next();
})

export default router
