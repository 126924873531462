import Names from '@/assets/names'
export default {
    NAMES: Names,

    GENDERS: ['male', 'female', 'both'],
    LIMITS: [5,10,20,50],

    DEFAULT_GENDER: 'both',
    DEFAULT_NUMBER: 10,
    DEFAULT_COUNTRY : '',
    DEFAULT_ADD_SURNAME: true,

    FIRST_NAME_ONLY: ['Muhammad'],
    RESTRICTED_NAMES: [],

    KEY_SHORTCUTS: [
        { "key": "Alt+P", "name": "Generate 1 Paragraph" },
        { "key": "Alt+W", "name": "Generate 1 Word" },
        { "key": "Alt+S", "name": "Generate 1 Sentence" },
        { "key": "Alt+O", "name": "Generate 1 Ordered List" },
        { "key": "Alt+U", "name": "Generate 1 Unordered List" },
        { "key": "Alt+X", "name": "Refresh Page" },
        { "key": "Alt+/", "name": "Toggle Show Shortcuts" }
    ]
}