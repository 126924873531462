import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { ElAlert, ElAside, ElAutocomplete, ElAvatar, ElBacktop, ElBadge, ElBreadcrumb, ElBreadcrumbItem, ElButton, ElButtonGroup, ElCalendar, ElCard, ElCarousel, ElCarouselItem, ElCascader, ElCascaderPanel, ElCheckbox, ElCheckboxButton, ElCheckboxGroup, ElCol, ElCollapse, ElCollapseItem, ElCollapseTransition, ElColorPicker, ElContainer, ElDatePicker, ElDialog, ElDivider, ElDrawer, ElDropdown, ElDropdownItem, ElDropdownMenu, ElFooter, ElForm, ElFormItem, ElHeader, ElIcon, ElImage, ElInput, ElInputNumber, ElLink, ElMain, ElMenu, ElMenuItem, ElMenuItemGroup, ElOption, ElOptionGroup, ElPageHeader, ElPagination, ElPopconfirm, ElPopover, ElPopper, ElProgress, ElRadio, ElRadioButton, ElRadioGroup, ElRate, ElRow, ElScrollbar, ElSelect, ElSlider, ElStep, ElSteps, ElSubmenu, ElSwitch, ElTabPane, ElTable, ElTableColumn, ElTabs, ElTag, ElTimePicker, ElTimeSelect, ElTimeline, ElTimelineItem, ElTooltip, ElTransfer, ElTree, ElUpload, ElInfiniteScroll, ElLoading, ElMessage, ElMessageBox, ElNotification,
} from 'element-plus';

import i18n from './i18n'

import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/display.css'
import '@/css/main.css'

const elementComponents = [ ElAlert, ElAside, ElAutocomplete, ElAvatar, ElBacktop, ElBadge, ElBreadcrumb, ElBreadcrumbItem, ElButton, ElButtonGroup, ElCalendar, ElCard, ElCarousel, ElCarouselItem, ElCascader, ElCascaderPanel, ElCheckbox, ElCheckboxButton, ElCheckboxGroup, ElCol, ElCollapse, ElCollapseItem, ElCollapseTransition, ElColorPicker, ElContainer, ElDatePicker, ElDialog, ElDivider, ElDrawer, ElDropdown, ElDropdownItem, ElDropdownMenu, ElFooter, ElForm, ElFormItem, ElHeader, ElIcon, ElImage, ElInput, ElInputNumber, ElLink, ElMain, ElMenu, ElMenuItem, ElMenuItemGroup, ElOption, ElOptionGroup, ElPageHeader, ElPagination, ElPopconfirm, ElPopover, ElPopper, ElProgress, ElRadio, ElRadioButton, ElRadioGroup, ElRate, ElRow, ElScrollbar, ElSelect, ElSlider, ElStep, ElSteps, ElSubmenu, ElSwitch, ElTabPane, ElTable, ElTableColumn, ElTabs, ElTag, ElTimePicker, ElTimeSelect, ElTimeline, ElTimelineItem, ElTooltip, ElTransfer, ElTree, ElUpload, ]
const elementPlugins = [ ElInfiniteScroll, ElLoading, ElMessage, ElMessageBox, ElNotification, ]

const app = createApp(App)

app.use(router)

/** Element config below */
elementComponents.forEach(component => {
    app.component(component.name, component)
})

elementPlugins.forEach(plugin => {
    app.use(plugin)
})

app.use(i18n)


app.mount('#app')