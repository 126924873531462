<template>
    <el-row :gutter="12">
        <el-col :span="9">
            <el-card shadow="hover">
                <h1>
                    {{ $t('homepage.cards.lipsum.title') }}
                </h1>
                <el-divider />
                <div class="home-page-preview">
                    <p> 
                        <lorem-ipsum add="4s" />
                    </p>
                    <!-- <lorem-ipsum add="3s" /> -->
                    <ul>
                        <li v-for="i in range(4)" :key="i">
                            <lorem-ipsum add="1s" />
                        </li>
                    </ul>
                    <p>
                        <lorem-ipsum add="3s" />
                    </p>
                    <ol>
                        <li v-for="i in range(3)" :key="i">
                            <lorem-ipsum add="1s" />
                        </li>
                    </ol>
                </div>
                <el-divider />

                <router-link to="/lorem-ipsum">
                    <el-button type="primary">
                        {{ $t('homepage.cards.lipsum.action-btn') }}
                        <i class="el-icon-arrow-right"></i>
                    </el-button>
                </router-link>
            </el-card>
        </el-col>
        <el-col :span="9">
            <el-card shadow="hover">
                <h1>
                    {{ $t('homepage.cards.names.title') }}
                </h1>
                <el-divider />
                <display-names
                    :firstNames="firstNames"
                    :surnames="surnames"
                    :region="region"
                    :limit="5"
                    :include_surname="false"
                    :showIndex="false"
                />
                <el-divider />
                <router-link to="/names">
                    <el-button type="primary">
                        {{ $t('homepage.cards.names.action-btn') }}
                        <i class="el-icon-arrow-right"></i>
                    </el-button>
                </router-link>
                <span class="space"></span>
                <el-button @click="region = getRandomRegion()">
                    {{ $t('homepage.cards.names.refresh-btn') }}&nbsp;
                    <i class="el-icon-refresh-left"></i>
                </el-button>
            </el-card>
        </el-col>
        <el-col :span="6">
            <el-card shadow="hover">
                <h1>
                    {{ $t('homepage.cards.image.title') }}
                </h1>
                <el-divider />
                <el-image
                    :src="imageUrl"
                    lazy
                ></el-image>
                <el-divider />
                <router-link to="/names">
                    <el-button type="primary">
                        {{ $t('homepage.cards.image.action-btn') }}
                        <i class="el-icon-arrow-right"></i>
                    </el-button>
                </router-link>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import LoremIpsum from '../LoremIpsum.vue'
import DisplayNames from '@/components/DisplayNames'
import NamesMixin from '@/mixins/names'
import DefaultImageString from '@/settings/default-image-string'

export default {
    components: { LoremIpsum, DisplayNames },
    
    mixins: [ NamesMixin ],

    data(){
        return {
            gender: 'both',
            region: this.getRandomRegion(),
            imageUrl: DefaultImageString
        }
    },

    methods: {
        range(r){
            return new Array(r);
        }
    }
}
</script>
