<template>
    <el-table
        :data="names"
        :fit="true"
    >
        <el-table-column v-if="showIndex" type="index" width="80"></el-table-column>
        <el-table-column prop="name" width="240" :label="$t('s-names.table-header.name')"></el-table-column>
        <el-table-column v-if="showRegion" prop="region" width="240" :label="$t('s-names.table-header.region')"></el-table-column>
    </el-table>
</template>

<script>
import _ from 'lodash'
import Settings from '@/settings/names'

export default {
    props: {
        firstNames: {
            type: Array,
            default: ()=>{return []},
            required: true,
        },
        surnames: {
            type: Array,
            default: ()=>{return []},
            required: true,
        },
        region: {
            type: String,
            required: true,
        },
        limit: {
            type: Number,
            default: 10,
        },
        include_surname: {
            type: Boolean,
            default: true,
        },
        showRegion: {
            type: Boolean,
            default: true
        },
        showIndex: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        names(){
            let _namesFilter = this.firstNames.map(fName=>{
                if (Settings?.RESTRICTED_NAMES?.includes?.(fName)) return null

                let name = String(fName)
                if (Settings?.FIRST_NAME_ONLY?.includes?.(name)) return name

                if (this?.include_surname) {
                    let surname = this.getRandomSurname
                    name += ` ${surname}`
                }
                return { name, region: this.region }
            }).filter(Boolean)
            return _.take(_.shuffle(_namesFilter), this.limit)
        },

        getRandomSurname(){
            return _.shuffle(this.surnames)[0]
        },
    },
}
</script>

<style>

</style>